import { defineStore } from "pinia";
import { supabase } from "@/lib/supabase";
import { Provider } from "@supabase/supabase-js";

export const useAuthStore = defineStore("auth", {
  state: () => {
    return {
      currentUser: supabase.auth.user(),
      loggingIn: false,
      errorMessage: false,
    };
  },
  actions: {
    async handleLogin(
      provider: Provider | "email" = "email",
      email?: string,
      additionalOptions?: object
    ) {
      try {
        this.loggingIn = true;
        if (provider !== "email") {
          const { error, user, session } = await supabase.auth.signIn(
            {
              provider,
            },
            {
              redirectTo: `${location.protocol}//${location.host}`,
              ...(additionalOptions ?? {}),
            }
          );
          if (error) throw error;
        } else {
          const { error } = await supabase.auth.signIn({ email: email });
          if (error) throw error;
          alert("Check your email for the login link!");
        }
        this.errorMessage = false;
      } catch (e: any) {
        console.error(e.error_description || e.message);
        this.errorMessage = e.message;
      } finally {
        this.loggingIn = false;
      }
    },
    handleLogout() {
      supabase.auth.signOut();
    },
  },
  getters: {
    isAuth: (state) => !!state.currentUser,
  },
});

supabase.auth.onAuthStateChange((_, session) => {
  const authStore = useAuthStore();
  if (session?.user) {
    authStore.currentUser = session?.user;
  } else {
    authStore.currentUser = null;
  }
});
