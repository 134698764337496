import Dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import duration from "dayjs/plugin/duration";
import calendar from "dayjs/plugin/calendar";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

Dayjs.extend(relativeTime);
Dayjs.extend(duration);
Dayjs.extend(calendar);
Dayjs.extend(utc);
Dayjs.extend(timezone);

export default Dayjs;
