import { Client } from "@microsoft/microsoft-graph-client";
import { supabase } from "./supabase";
import { AuthenticationProvider } from "@microsoft/microsoft-graph-client";
import axios from "axios";
import Dayjs from "./dayjs";

const CLIENT_ID = process.env.VUE_APP_MS_AUTH_CLIENTID as string;
const SCOPES = process.env.VUE_APP_MS_AUTH_SCOPES as string;

const cachedServices = {} as { [id: string]: any };

// MyAuthenticationProvider.ts
const newRemoteServiceCredentialProvider = function (credential_id: string) {
  return {
    /**
     * This method will get called before every request to the msgraph server
     * This should return a Promise that resolves to an accessToken (in case of success) or rejects with error (in case of failure)
     * Basically this method will contain the implementation for getting and refreshing accessTokens
     */
    async getAccessToken(): Promise<string> {
      let service_data = cachedServices[credential_id];
      if (!service_data) {
        service_data = (
          await supabase
            .from("remote_service_credentials")
            .select("*")
            .eq("id", credential_id)
            .maybeSingle()
        ).data;
      }
      if (!service_data) {
        throw new Error("service not found");
      }

      // console.log("using service data: ", service_data);

      let token_data_expiry = 0;
      try {
        token_data_expiry = Dayjs(service_data.token_expires_at).unix();
      } catch (e) {
        console.log("unable to get token_data_expiry", e);
      }

      if (
        !service_data.token_expires_at ||
        token_data_expiry < Dayjs().unix()
      ) {
        const { data: token_data } = await supabase.functions.invoke(
          "get-remote-service-access-token",
          {
            body: JSON.stringify({
              remote_service_account_id: credential_id,
            }),
          }
        );
        service_data = token_data;
      }

      return service_data.access_token;
    },
  } as AuthenticationProvider;
};

const clients = {} as { [id: string]: Client };

export const useGraphClient = function (accountid: string) {
  let client = clients[accountid];

  const authProvider = newRemoteServiceCredentialProvider(accountid);

  client = Client.initWithMiddleware({ authProvider });

  clients[accountid] = client;

  return client;
};
