import { useTaskStore } from "./../stores/tasks";
import { createRouter, createWebHistory } from "@ionic/vue-router";
import { RouteRecordRaw } from "vue-router";
import TabsPage from "../views/Layout.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    redirect: "/tabs/tasks",
  },
  {
    path: "/services/:service_name/callback",
    component: () => import("@/views/services/callback.vue"),
  },

  {
    path: "/tabs/",
    component: TabsPage,
    children: [
      {
        path: "",
        redirect: "/tabs/tasks",
      },
      {
        path: "completed",
        component: () => import("@/views/tasks/completed.vue"),
      },
      {
        path: "calendar",
        component: () => import("@/views/calendar.vue"),
      },
      {
        path: "profile",
        component: () => import("@/views/profile.vue"),
      },
      {
        path: "tasks",
        alias: ["/tasks"],
        component: () => import("@/views/tasks/index.vue"),
      },
      {
        path: "tasks/:id",
        alias: ["/tasks/:id"],
        component: () => import("@/views/tasks/_id.vue"),
        beforeEnter: (to, from) => {
          // console.log('routeGuard: ', to, from)
          const taskStore = useTaskStore();
          const theTask = taskStore.findById(<string>to.params.id);
          if (!theTask) {
            return "/tabs/tasks";
          } else if (theTask.is_template) {
            return `/tabs/templates/${theTask.id}`;
          }
        },
      },
      {
        path: "templates",
        alias: ["/templates"],
        component: () => import("@/views/templates/index.vue"),
      },
      {
        path: "templates/:id",
        alias: ["/templates/:id"],
        component: () => import("@/views/tasks/_id.vue"),
        beforeEnter: (to, from) => {
          // console.log('routeGuard: ', to, from)
          const taskStore = useTaskStore();
          const theTask = taskStore.findById(<string>to.params.id);
          if (!theTask) {
            return "/tabs/templates";
          } else if (!theTask.is_template) {
            return `/tabs/tasks/${theTask.id}`;
          }
        },
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
