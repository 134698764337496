import { createClient } from "@supabase/supabase-js";

const supabaseUrl = <string>process.env.VUE_APP_SUPABASE_URL;
const supabaseAnonKey = <string>process.env.VUE_APP_SUPABASE_ANON_KEY;

console.log(
  "supabase: ",
  process.env.VUE_APP_SUPABASE_URL,
  process.env.VUE_APP_SUPABASE_ANON_KEY
);

if (!supabaseUrl || !supabaseAnonKey) {
  console.error("SUPABASE URL NOT DEFINED. EXITING!!!!");
}

export const supabase = createClient(supabaseUrl, supabaseAnonKey, {
  autoRefreshToken: true,
});
