import { useGraphClient } from "./../lib/m365Graph";
import { defineStore } from "pinia";
import { supabase } from "@/lib/supabase";

export interface remote_service_credentials {
  id: string;
  id_token?: string;
  access_token?: string;
  refresh_token?: string;
  nickname?: string;
  token_expires_at: Date | null;
}

interface remote_service_state {
  accounts: remote_service_credentials[];
  accountLists: {
    [id: string]: any[];
  };
  loading: boolean;
  display_lists: string[];
  accountCalendars: Record<string, unknown>;
}

export const useRemoteServices = defineStore("remote_services", {
  state: (): remote_service_state => {
    return {
      accounts: [],
      loading: false,
      accountLists: {},
      display_lists: [],
      accountCalendars: {},
    };
  },
  actions: {
    async getAccounts() {
      try {
        this.loading = true;

        const { data } = (await supabase
          .from("remote_service_credentials")
          .select("*")) as any;

        this.accounts = data;
        this.getLists();
        this.getCalendars();
      } catch (e) {
        console.error(e);
      } finally {
        this.loading = false;
      }
    },
    async deleteAccount(id: string) {
      await supabase.from("remote_service_credentials").delete().eq("id", id);
      this.getAccounts();
    },
    async getLists() {
      try {
        for (const acc of this.accounts) {
          const lists = await useGraphClient(acc.id).api("me/todo/lists").get();
          console.log("lists: ", lists.value);
          this.accountLists[acc.id] = lists.value;
        }
      } catch (e) {
        console.error(e);
      }
      return this.accountLists;
    },
    async getSyncLists(accountId: string) {
      const lists = await this.getLists();
      return lists[accountId].filter((list) =>
        this.display_lists.find((l) => l == list.id)
      );
    },
    async getCalendars() {
      try {
        for (const acc of this.accounts) {
          const lists = await useGraphClient(acc.id).api("me/calendars").get();
          console.log("lists: ", lists.value);
          this.accountCalendars[acc.id] = lists.value;
        }
      } catch (e) {
        console.error(e);
      }
    },
  },
  // async login() { },
  // async handleCallback() { }
  // async logout() { }

  getters: {
    account: (state) => (id: string) => state.accounts.find((a) => a.id === id),
    // accountLists(accountId) {},
  },
});
