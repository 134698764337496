import { defineStore } from "pinia";
import { cloneDeep, orderBy, uniqBy } from "lodash";
import { useAuthStore } from "./auth";
import { supabase } from "../lib/supabase";

import { v4 as uuidv4 } from "uuid";

const REMOTE_POLL_DURATION = 60 * 1000;
const MUST_USE_KEYS = ["id", "label"];

export interface TaskInterface {
  id?: string;
  label?: string;
  completed_at?: Date | null;
  created_at?: Date;
  parent_id?: string | null;
  description?: string | null;
  idx?: number;
  is_template?: boolean;
  _deleted?: boolean;
  prev_task_id?: string | null;
  due_by?: Date | null;
  remind_at?: Date | null;
  estimated_minutes?: number | null;
}
export interface Task extends TaskInterface {
  id: string;
  label: string;
  created_at: Date;
  idx: number;
}
export interface NewTask extends TaskInterface {
  label: string;
}
export interface TaskUpdateModel extends TaskInterface {
  id: string;
}

export type TaskList = Task[];

export interface state {
  isSyncing: boolean;
  lastSync?: Date | null;
  syncResult: true | null | string;
  localData: Task[];
  remoteData: Task[];
  locallyDeleted: string[];
}

let syncTimerID: number | null = null;

export const useTaskStore = defineStore("Tasks", {
  state: (): state => {
    return {
      isSyncing: false,
      lastSync: null,
      syncResult: null,
      localData: [],
      remoteData: [],
      locallyDeleted: [],
    };
  },
  actions: {
    async syncWithRemote() {
      console.groupCollapsed("syncing with remote");
      const authStore = useAuthStore();
      if (!authStore.currentUser) {
        console.error("Can't syncWithRemote() cause not logged in ");
        console.groupEnd();
        return;
      }
      // if (this.isSyncing) {
      //   return;
      // }

      this.isSyncing = true;
      if (syncTimerID) {
        console.debug("clearing syncTimerID: ", syncTimerID);
        clearTimeout(syncTimerID);
      }
      try {
        const changesToPushKeyMap = new Map();

        for (const localTask of this.localData) {
          const remoteTask = this.remoteData.find((t) => t.id === localTask.id);

          //assume the whole object is new and needs to be pushed
          let changeObj: any = localTask;
          let changedKeysFromLastSync: string[] = Object.keys(changeObj);

          //get the keys that have been added/changed locally since last sync
          if (remoteTask) {
            changedKeysFromLastSync = getObjectDiff(
              localTask,
              remoteTask
            ).filter((i) => i);

            if (changedKeysFromLastSync.length) {
              (changeObj as any) = { id: localTask.id };
              for (const key of changedKeysFromLastSync) {
                changeObj[key] = localTask[<keyof Task>key];
              }
            } else {
              //if nothing changed, and it's an exact match, then don't queue for changes.
              continue;
            }
          }

          //must include certain keys when upserting to the database
          if (MUST_USE_KEYS.length) {
            for (const key of MUST_USE_KEYS) {
              changeObj[key] = localTask[<keyof Task>key];
            }
          }

          // if we have some changes, then put them in the approriate queue
          // (the object keys need to match for batch processing)
          if (!changesToPushKeyMap.has(changedKeysFromLastSync.join(","))) {
            changesToPushKeyMap.set(changedKeysFromLastSync.join(","), []);
          }

          changesToPushKeyMap
            .get(changedKeysFromLastSync.join(","))
            .push(changeObj);
        }

        console.debug("changesToPushKeyMap: ", changesToPushKeyMap);

        try {
          for (const changesGroupedByKeys of changesToPushKeyMap.values()) {
            const push = await supabase
              .from("task")
              .upsert(uniqBy(changesGroupedByKeys, "id"), {
                returning: "minimal",
              });
            if (push.error) throw push.error;
            console.debug("Pushed data: ", push);
          }
        } catch (e) {
          console.error(e);
          throw e;
        }

        try {
          const deletions = await supabase
            .from("task")
            .delete()
            .in("id", this.locallyDeleted);
          if (deletions.error) throw deletions.error;
          console.debug("Deleted rows: ", deletions.data);
          this.locallyDeleted = [];
        } catch (e) {
          console.error(e);
          throw e;
        }

        try {
          const pull = await supabase
            .from("task")
            .select(
              "id, label, completed_at, created_at, parent_id, description, is_template, prev_task_id, idx, remind_at, estimated_minutes, due_by"
            );
          if (pull.error) throw pull.error;
          console.debug("Pulled rows: ", pull.data);

          this.remoteData = pull.data.filter(
            (t) => !this.locallyDeleted.includes(t.id)
          );

          console.debug("remoteData: ", pull.data);
          this.lastSync = new Date();
          this.localData = cloneDeep(pull.data);
          this.sort();
          this.syncResult = true;
        } catch (e) {
          console.error(e);
          throw e;
        }
      } catch (e: any) {
        this.syncResult = e.message;
        if (e.message == "JWT expired") supabase.auth.refreshSession();
      } finally {
        this.isSyncing = false;
        this.resetSyncTimer(REMOTE_POLL_DURATION);
        console.debug("setting new syncTimerID: ", syncTimerID);
        console.groupEnd();
      }
    },
    resetSyncTimer(duration: number) {
      if (syncTimerID) {
        clearTimeout(syncTimerID);
      }
      syncTimerID = setTimeout(
        () => this.syncWithRemote(),
        duration ?? REMOTE_POLL_DURATION
      ) as unknown as number;
    },
    // getChanges() {},

    // async loadLocal() {},
    // async checkOnline() { },
    add(task: Task | NewTask): Task | false {
      if (task.id) {
        return false;
      }
      task.id = uuidv4();
      task.description = "";
      task.parent_id = task.parent_id ?? null;
      task.created_at = new Date();
      task.completed_at = null;

      if (task.prev_task_id) {
        this.update_prev_task_pointers(task.prev_task_id, task.id);
      }

      console.debug("adding task: ", task);
      this.localData.push(<Task>task);
      this.sort();

      const newTask = this.findById(task.id);

      this.resetSyncTimer(1000);

      return <Task>newTask;
    },
    update(task: Task) {
      if (task?.id) {
        const theTaskIdx = this.localData.findIndex((t) => t.id === task.id);

        this.localData[theTaskIdx] = { ...this.localData[theTaskIdx], ...task };

        if (task.estimated_minutes && task.parent_id) {
          const totalParentTime = this.localData
            .filter(
              (t) => t.parent_id == task.parent_id && task.estimated_minutes
            )
            .reduce(
              (m, t) => (t.estimated_minutes ? m + t.estimated_minutes : m),
              0
            );
          console.log("total parent time is ", totalParentTime);
          const parent = this.localData.find((t) => t.id == task.parent_id);
          if (
            parent &&
            (!parent.estimated_minutes ||
              parent.estimated_minutes < totalParentTime)
          ) {
            parent.estimated_minutes = totalParentTime;
            this.update(parent);
          }
        }

        this.sort();
      } else {
        this.add(task);
      }
      this.resetSyncTimer(1000);
    },
    delete(taskOrId: Task | string) {
      // console.debug("attempting to delete: ", taskOrId);
      const theId = typeof taskOrId === "string" ? taskOrId : taskOrId?.id;

      if (this.remoteData.find((rt) => rt.id === theId)) {
        //mark for deletion to remote if on remote
        this.locallyDeleted.push(theId);
      }

      this.localData
        .filter((t: Task) => t.parent_id === theId)
        .map(this.delete);
      const idx = this.localData.findIndex((t: Task) => t.id === theId);

      const [theDeletedTask] = this.localData.splice(idx, 1);

      //close the gap for any tasks that were listed after this one
      this.update_prev_task_pointers(
        theDeletedTask.id,
        theDeletedTask.prev_task_id
      );

      this.resetSyncTimer(1000);
    },
    update_prev_task_pointers(
      from?: string | null,
      to?: string | null,
      array?: TaskList
    ) {
      if (from === null && !array) {
        return;
      }
      array = array || this.localData;
      array.forEach((t) => {
        if (t.prev_task_id === from) {
          console.log("updating task ", t, "from", from, "to", to);
          t.prev_task_id = to;
        }
      });
    },
    findById(id: string): Task | false {
      return this.localData.find((t) => t.id === id) || false;
    },
    findChildrenOf(id: string): Task[] {
      return this.localData.filter((t) => t.parent_id === id);
    },
    addTaskFromTemplate(
      templateId: string,
      parentId: string | null = null
    ): Task | false {
      const theTemplate = this.findById(templateId);
      console.debug("addTaskFromTemplate theTemplate", theTemplate);
      if (!theTemplate) return false;
      const newTask = <NewTask>{
        ...theTemplate,
        is_template: false,
        parent_id: parentId,
      };
      delete newTask.id;

      console.debug("addTaskFromTemplate newTask", newTask);
      const theTask = this.add(newTask);
      if (theTask && theTask.id) {
        const childTemplates = this.findChildrenOf(templateId);
        childTemplates.forEach((t) =>
          this.addTaskFromTemplate(t.id, theTask.id)
        );
      }

      return theTask;
    },
    getAncestors(taskId: string | null, includeSelf = false): TaskList {
      console.log("getting ancestors for task: ", taskId);
      if (!taskId) {
        return [];
      }
      let theTask = this.findById(taskId) as Task;
      const hierarchy = includeSelf ? [theTask] : [];
      while (theTask?.parent_id) {
        theTask = this.findById(theTask?.parent_id) as Task;
        hierarchy.push(theTask);
      }
      return hierarchy.filter((i) => i).reverse();
    },
    /**
     *
     * @param task Task
     * @param toIdx (starting at 0)
     * @param fromIdx (starting at 0)
     * @returns
     */
    // move(task: Task, toIdx: number, fromIdx: number) {
    //   fromIdx = fromIdx || task.idx;

    //   if (fromIdx == toIdx) return;
    //   console.debug("moving ", task.label, " from ", fromIdx, "to", toIdx);

    //   const taskId = task.id;
    //   const theTask = this.findById(taskId);
    //   if (!theTask) return;

    //   //if you're moving UP (e.g. 7 -> 3), then shift everything from TO (3) UP till you get to the FROM (7)
    //   //if you're moving DOWN, then (e.g. 4 -> 9), then shift everything from TO (9) down till FROM (4)

    //   const siblings = this.getSiblings(theTask);

    //   console.debug("siblings: ", siblings);

    //   let i = 0;
    //   siblings.splice(toIdx, 0, theTask);

    //   for (const sib of siblings) {
    //     // const oldIdx = sib.idx
    //     sib.idx = i;
    //     // console.debug(i, 'assigning index from', oldIdx, ' => ', sib.idx,' on sibling: ', sib.label)
    //     i++;
    //   }
    //   this.sort();
    // },
    sort() {
      this.localData = orderBy(
        this.localData,
        ["idx", "created_at"],
        ["asc", "desc"]
      );
    },
  },
  getters: {
    sortedTasks: (state) =>
      orderBy(state.localData, ["idx", "created_at"], ["asc", "desc"]),

    tasks(): TaskList {
      return this.sortedTasks.filter((t) => !t.is_template);
    },

    templates(): TaskList {
      return this.sortedTasks.filter((t) => t.is_template);
    },
    totalItems:
      (state) =>
      (taskId: string): number => {
        return state.localData.filter((t) => t.parent_id === taskId).length;
      },
    pendingTasks(state) {
      return (parentId: string | null = null) => {
        return this.sortedTasks.filter(
          (t) => !t.is_template && t.parent_id === parentId && !t.completed_at
        );
      };
    },
    completedItems:
      (state) =>
      (taskId: string): number => {
        return state.localData.filter(
          (t) => t.parent_id === taskId && t.completed_at
        ).length;
      },
  },
});

supabase.auth.onAuthStateChange((ev, session) => {
  console.debug(ev);
  if (ev === "SIGNED_IN") {
    const taskStore = useTaskStore();
    if (session?.user) {
      taskStore.syncWithRemote();
    }
  }
});

import { isEqual } from "lodash";
function getObjectDiff(obj1: any, obj2: any, includeObj2Keys = false) {
  const diff = Object.keys(obj1).reduce(
    (result, key) => {
      if (includeObj2Keys && !Object.prototype.hasOwnProperty.call(obj2, key)) {
        result.push(key);
      } else if (isEqual(obj1[key], obj2[key])) {
        const resultKeyIndex = result.indexOf(key);
        result.splice(resultKeyIndex, 1);
      }
      return result;
    },
    includeObj2Keys ? Object.keys(obj2) : Object.keys(obj1)
  );

  return diff;
}
