/* eslint-disable no-console */

import { register } from "register-service-worker";
import { precacheAndRoute } from "workbox-precaching";

if (process.env.NODE_ENV === "production") {
  precacheAndRoute((self as any).__WB_MANIFEST || []);

  register(`${process.env.BASE_URL}service-worker.js`, {
    async ready(serviceWorker) {
      console.log(
        "App is being served from cache by a service worker.\n" +
          "For more details, visit https://goo.gl/AFskqB"
      );
      const testPushSubscription = await serviceWorker.pushManager.subscribe({
        userVisibleOnly: false,
      });
      console.log("testPushSubscription: ", testPushSubscription);
    },
    async registered(serviceWorker) {
      console.log("Service worker has been registered.");
    },
    cached() {
      console.log("Content has been cached for offline use.");
    },
    updatefound() {
      console.log("New content is downloading.");
    },
    updated() {
      console.log("New content is available; please refresh.");
    },
    offline() {
      console.log(
        "No internet connection found. App is running in offline mode."
      );
    },
    error(error) {
      console.error("Error during service worker registration:", error);
    },
  });
}
